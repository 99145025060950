import React, { useState, useEffect } from 'react';
import { GrStatusGoodSmall } from "react-icons/gr";
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import Edit from '../mobile/editpackage/edit';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Delete from '../deletemodal/confirm_delete';
import Create from '../mobile/createpackage/create';
import Skeleton from 'react-loading-skeleton';

const Mobile = () => {
  const token = useSelector(state => state.login.logintoken);
  const [openSectionIndex, setOpenSectionIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const [planToDelete, setPlanToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);

  const fetchPlans = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://api.mystarsonline.com/api/Subscription/get-MobilePlans');
      if (response.data.code === 200) {
        setPlans(response.data.data);
      } else {
        setError('Failed to load plans.');
      }
    } catch (error) {
      setError('Error fetching plans.');
      console.error("Error fetching plans:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const toggleSection = (sectionIndex) => {
    setOpenSectionIndex(prevIndex => (prevIndex === sectionIndex ? null : sectionIndex));
  };

  const handleOpenDeleteModal = (plan) => {
    setPlanToDelete(plan);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setPlanToDelete(null);
  };

  const handleDelete = async () => {
    if (!planToDelete) return;
    const planCode = planToDelete.monthlyPlan.paystackPlanCode;
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `https://api.mystarsonline.com/api/Subscription/delete-plan/${planCode}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        await fetchPlans();
      }
    } catch (error) {
      console.error("Error deleting plan:", error);
    } finally {
      setIsLoading(false);
      handleCloseDeleteModal();
    }
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleOpenEditModal = (plan) => {
    const planDetails = plan.monthlyPlan;
    setSelectedPlanDetails(planDetails);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedPlanDetails(null);
  };

  const handleSavePlan = async () => {
    await fetchPlans();
    handleCloseCreateModal();
  };

  return (
    <div className="grid grid-cols-2 ">
      {isLoading && <p className='pt-20'><Skeleton count={8} /></p>}
      {error && <p className="text-red-600 text-center">Error: {error}</p>}
      {!isLoading && !error && plans.map((plan, index) => (
        <div >
        <section key={index} className=' rounded-xl border-black shadow-lg my-4 border mx-14'>
          <div className='p-4 flex flex-col '>
            <div className="flex flex-col    rounded-xl p-3 " style={{ backgroundColor: plan.monthlyPlan.colorCode }}>
             <div className='flex space-x-2 items-end justify-end'>
                  <div className="text-xs border text-white rounded-full px-2 py-1" onClick={() => handleOpenEditModal(plan)}>
                     <button >edit</button>
                  </div> 
                  <div onClick={() => handleOpenDeleteModal(plan)} className="text-xs border text-white rounded-full px-2 py-1">
                    <button >delete</button>
                  </div>
                  </div>
              <h1 className="font-bold pt-3 justify-center text-center text-xl text-white">
                {plan.monthlyPlan.name}
              </h1>
              <p className='text-white flex justify-center mt-2'>{`Up to ${plan.monthlyPlan.maxStudentCapacity || 'N/A'} students`}</p>
            </div>
           <div className='px-3'>

          
            <ul className='mt-2 list-disc pl-4'>
              {plan.monthlyPlan.keyFeatures.split(',').map((feature, idx) => (
                <li key={idx} className="text-sm text-gray-600">{feature}</li>
              ))}
            </ul>
            <div className='flex justify-between items-center mt-4'>
              <p className='text-sm flex flex-col'>
                <span className='font-bold'>Monthly:</span> {plan.monthlyPlan.amount} NGN
              </p>
              <p className='text-sm flex flex-col'>
                <span className='font-bold'>Yearly:</span> {plan.monthlyPlan.yearlyAmount} NGN
              </p>
            </div>
            </div>
          </div>
        </section>
      </div>
      
      
      ))}

      <div className='flex justify-end pt-5'>
        <button onClick={handleOpenCreateModal} className='text-sm border border-black rounded-lg px-3 py-2'>
          + Add New Package
        </button>
      </div>

      {isEditModalOpen && (
        <div style={{
          position: 'fixed',
          inset: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 50
        }}>
          <Edit onClose={handleCloseEditModal} selectedPlanDetails={selectedPlanDetails} onSave={handleSavePlan} />
        </div>
      )}

      {isCreateModalOpen && (
        <div style={{
          position: 'fixed',
          inset: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 50
        }}>
          <Create onClose={handleCloseCreateModal} onSave={handleSavePlan} />
        </div>
      )}

      {isDeleteModalOpen && (
        <div style={{
          position: 'fixed',
          inset: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 50
        }}>
          <Delete onClose={handleCloseDeleteModal} onConfirm={handleDelete} isLoading={isLoading} />
        </div>
      )}
    </div>
  );
};

export default Mobile;
