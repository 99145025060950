// import React, { useState, useEffect } from 'react';
// import { GrStatusGoodSmall } from "react-icons/gr";
// import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
// import Edit from './editpackage/edit'; // Ensure this import is correct
// import axios from 'axios';
// import { useSelector } from 'react-redux'; // Redux import to access the token
// import Delete from '../deletemodal/confirm_delete'; // Ensure this import is correct
// import Create from './createpackage/create'; // Ensure this import is correct
// import Skeleton from 'react-loading-skeleton'; // Make sure to install react-loading-skeleton

// const Web = () => {
//   const token = useSelector(state => state.login.logintoken); // Access token from Redux
//   const [openSectionIndex, setOpenSectionIndex] = useState(null);
//   const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//   const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
//   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
//   const [plans, setPlans] = useState([]);
//   const [planToDelete, setPlanToDelete] = useState(null); // Stores the plan to delete
//   const [isLoading, setIsLoading] = useState(true); // Manage loading state
//   const [error, setError] = useState(null); // Manage error state
//   const [selectedPlanDetails, setSelectedPlanDetails] = useState(null); // Store selected plan details for editing
//   const [activeTab, setActiveTab] = useState('monthly'); // State to control the active tab (monthly/annual)

//   // Fetch plans from the API
//   const fetchPlans = async () => {
//     setIsLoading(true); // Set loading state to true before fetching data
//     setError(null); // Reset the error state

//     try {
//       const response = await axios.get('https://api.mystarsonline.com/api/Subscription/get-WebPlans');
//       if (response.data.code === 200) {
//         setPlans(response.data.data); // Set all plans into state
//       } else {
//         setError('Failed to load plans.');
//       }
//     } catch (error) {
//       setError('Error fetching plans.'); // Set error if fetching fails
//       console.error("Error fetching plans:", error);
//     } finally {
//       setIsLoading(false); // Set loading state to false after fetching
//     }
//   };

//   useEffect(() => {
//     fetchPlans(); // Fetch plans when the component loads
//   }, []);

//   // Function to toggle individual sections in accordion
//   const toggleSection = (sectionIndex) => {
//     setOpenSectionIndex(prevIndex => (prevIndex === sectionIndex ? null : sectionIndex));
//   };

//   // Open the delete confirmation modal
//   const handleOpenDeleteModal = (plan) => {
//     console.log("Plan to delete:", plan); // Debug: Log the plan to be deleted
//     setPlanToDelete(plan); // Store the plan to delete
//     setIsDeleteModalOpen(true); // Open delete modal
//   };

//   // Close the delete confirmation modal
//   const handleCloseDeleteModal = () => {
//     setIsDeleteModalOpen(false); // Close modal
//     setPlanToDelete(null); // Clear the plan to delete
//   };

//   // Handle delete request for the selected plan
//   const handleDelete = async () => {
//     if (!planToDelete) return;

//     const planCode = planToDelete.paystackPlanCode; // Directly access the paystackPlanCode from the planToDelete
//     console.log("Plan code to delete:", planCode); // Debugging log

//     if (!planCode) {
//       console.error("No plan code available for deletion.");
//       return;
//     }

//     setIsLoading(true); // Set loading state to true

//     try {
//       const response = await axios.delete(
//         `https://api.mystarsonline.com/api/Subscription/delete-plan/${planCode}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       if (response.status === 200) {
//         console.log("Plan deleted successfully.");
//         await fetchPlans(); // Refresh the plans after deletion
//       }
//     } catch (error) {
//       console.error("Error deleting plan:", error);
//     } finally {
//       setIsLoading(false); // Reset loading state
//       handleCloseDeleteModal(); // Close the modal after operation
//     }
//   };

//   // Open and close the Create modal
//   const handleOpenCreateModal = () => {
//     setIsCreateModalOpen(true); // Open create modal
//   };

//   const handleCloseCreateModal = () => {
//     setIsCreateModalOpen(false); // Close create modal
//   };

//   // Open and close the Edit modal
//   const handleOpenEditModal = (plan) => {
//     console.log("Plan object:", plan); // This is correct
//     const planDetails = plan; // Use the plan object directly
//     console.log("Plan Details:", planDetails); // Now this should log the correct details
//     setSelectedPlanDetails(planDetails); // Set the selected plan details
//     setIsEditModalOpen(true); // Open the edit modal
//   };

//   const handleCloseEditModal = () => {
//     setIsEditModalOpen(false); // Close edit modal
//     setSelectedPlanDetails(null); // Clear the selected plan details
//   };

//   // Handle saving a new plan after creation
//   const handleSavePlan = async () => {
//     await fetchPlans(); // Refresh the list of plans after saving
//     handleCloseCreateModal(); // Close the create modal
//   };

//   // Filter plans based on the active tab
//   const plansToDisplay = activeTab === 'monthly'
//     ? plans.map(plan => plan.monthlyPlan).filter(Boolean)
//     : plans.map(plan => plan.annualPlan).filter(Boolean);

//   return (
    
//     <div>
//       {/* Toggle Buttons for Monthly and Annual Plans */}
//       <div className="flex justify-start items-center p-4">
//         <div className="border rounded-xl border-black overflow-hidden flex">
//           <button
//             className={`font-bold cursor-pointer text-sm  px-2 py-1 ${activeTab === 'monthly' ? 'bg-purple-600 text-white' : 'bg-white text-gray-600'}`}
//             onClick={() => setActiveTab('monthly')}
//           >
//             Monthly Plans
//           </button>
//           <button
//             className={`font-bold cursor-pointer text-sm  px-2 py-1 ${activeTab === 'annual' ? 'bg-purple-600 text-white' : 'bg-white text-gray-600'}`}
//             onClick={() => setActiveTab('annual')}
//           >
//             Annual Plans
//           </button>
//         </div>
//       </div>

//       {/* Handle loading and error states */}
//       {isLoading && <p className='pt-20'><Skeleton count={8} /></p>}
//       {error && <p className="text-red-600 text-center">Error: {error}</p>}
//       {!isLoading && !error && plansToDisplay.map((plan, index) => (
//         <section key={index} className='bg-gray-100 rounded-xl shadow-md my-4'>
//           <div className='p-3 md:px-6'>
            
//             {/* Plan Header */}
//             <div className='flex flex-col md:flex-row md:justify-between md:py-2 md:px-4 md:border-b-2 md:items-center'>
//               <div className='flex justify-between space-x-3'>
//                 <h1 className='font-bold text-[12px] md:text-base'>{plan.name}</h1>
//                 <span className='flex text-center items-center bg-gray-200 px-2 rounded-2xl space-x-2'>
//                   <GrStatusGoodSmall style={{ color: plan.colorCode || 'black' }} className="text-[12px] md:text-base" />
//                   <span className='text-[12px] md:text-base'>Color</span>
//                 </span>
//               </div>
//               <div className='flex justify-between pt-4 md:pt-0'>
//                 <h1 className='font-bold text-[12px] md:text-base'>Amount</h1>
//                 <span className='flex text-[12px] md:text-base text-center items-center bg-gray-300 px-2 rounded-2xl space-x-2'>
//                   {`₦${(plan.amount / 100)?.toLocaleString() || 0} ${plan.currency}`}
//                 </span>
//               </div>
//               <div className='flex text-[12px] justify-between pt-4 md:space-x-3 md:items-center md:pt-0 md:text-sm'>
//                 <div className='p-1 border border-black md:px-4 cursor-pointer md:rounded-xl'>
//                   <button onClick={() => handleOpenDeleteModal(plan)}>Delete</button>
//                 </div>
//                 <div className='cursor-pointer'>
//                   <button onClick={() => handleOpenEditModal(plan)}> Edit</button>
//                 </div>
//               </div>
//             </div>

//             {/* Accordion Section */}
//             <div className='font-thin'>
//               <Accordion title="Key Features" open={openSectionIndex === `features-${index}`} onClick={() => toggleSection(`features-${index}`)}>
//                 <p className='text-[14px] md:text-base'>{plan.keyFeatures || 'No key features available'}</p>
//               </Accordion>
//               <Accordion title="Description" open={openSectionIndex === `description-${index}`} onClick={() => toggleSection(`description-${index}`)}>
//                 <p className='text-[14px] md:text-base'>{plan.description || 'No description available'}</p>
//               </Accordion>
//               <Accordion title="Ideal For" open={openSectionIndex === `idealfor-${index}`} onClick={() => toggleSection(`idealfor-${index}`)}>
//                 <p className='text-[14px] md:text-base'>{plan.idealFor || 'No details available'}</p>
//               </Accordion>
//             </div>
//           </div>
//         </section>
//       ))}

//       {/* Add New Package Button */}
//       <div className='flex justify-end pt-5'>
//         <div className='text-[10px] border border-black rounded-xl px-1 py-1 cursor-pointer md:text-base'>
//           <button onClick={handleOpenCreateModal}>+ Add New Package</button>
//         </div>
//       </div>

//       {/* Modals */}
//       {isEditModalOpen && (
//         <div style={{
//           position: 'fixed',
//           inset: '0',
//           backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           zIndex: 50
//         }}>
//           <Edit onClose={handleCloseEditModal} selectedPlanDetails={selectedPlanDetails} onSave={handleSavePlan} />
//         </div>
//       )}

//       {isCreateModalOpen && (
//         <div style={{
//           position: 'fixed',
//           inset: '0',
//           backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           zIndex: 50
//         }}>
//           <Create onClose={handleCloseCreateModal} onSave={handleSavePlan} />
//         </div>
//       )}

//       {isDeleteModalOpen && (
//         <div style={{
//           position: 'fixed',
//           inset: '0',
//           backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           zIndex: 50
//         }}>
//           <Delete onClose={handleCloseDeleteModal} onConfirm={handleDelete} isLoading={isLoading} />
//         </div>
//       )}
//     </div>
//   );
// };

// // Accordion Component for Reusability
// const Accordion = ({ title, open, onClick, children }) => (
//   <div className='p-3 w-full  cursor-pointer flex flex-col' onClick={onClick}>
//     <div className='flex flex-row justify-between'>
//     <div className='font-bold text-[12px] md:text-[15px]'>{title} </div>
//     {open ? <AiOutlineMinus className='font-extrabold' size={18} color={'black'} /> : <AiOutlinePlus size={18} className='font-extrabold' color={'black'} />}
//     </div>
//    {open && <div className='w-full overflow-hidden transition-max-h  '>{children}</div>}
//   </div>
// );

// export default Web;

import React, { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { GrStatusGoodSmall } from 'react-icons/gr';

// Dummy data provided
const sections = [
  {
    title: "SPE (Student, Parent, Employee)",
    features: [
      { name: "Employee Records Management", columns: [true] },
      { name: "Session Manager", columns: [true] },
      { name: "Campus Management", columns: [true] },
      { name: "Sport House Manager", columns: [true] },
      { name: "Control Panel", columns: [true] },
      { name: "Student Parent System", columns: [true] },
      { name: "Entrance Exam", columns: [true] },
      { name: "Classroom Management", columns: [true] },
      { name: "Parent Communication", columns: [true] },
      { name: "Progress Evaluation", columns: [true] },
      { name: "Personalized News Feed With Filtering", columns: [false] },
      { name: "Student Tracking System", columns: [false] },
      { name: "Mass Messaging", columns: [false] },
      { name: "One-Click Class Report", columns: [false] },
      { name: "Online Admissions", columns: [false] },
      { name: "Timetable", columns: [true] },
      { name: "Gradebooks", columns: [true] },
      { name: "Student Profiles", columns: [true] },
      { name: "Hostel Manager", columns: [false] },
    ],
  },
  {
    title: "Human Resources",
    features: [
      { name: "Employee Records Management", columns: [true] },
      { name: "Time-Off Management", columns: [true] },
      { name: "Application Tracking System", columns: [false] },
      { name: "Onboarding", columns: [true] },
      { name: "Offboarding", columns: [false] },
    ],
  },
  {
    title: "Accounting",
    features: [
      { name: "Fee Management (including online payments)", columns: [true] },
      { name: "Invoice Generation & Management", columns: [false] },
      { name: "Expense Management", columns: [false] },
      { name: "Budget Tracking & Reporting", columns: [false] },
      { name: "Financial Reports (Customizable)", columns: [false] },
    ],
  },
  {
    title: "Inventory Management (Optional)",
    features: [
      { name: "School Supply Inventory Management", columns: [true] },
      { name: "Library Book Inventory Management", columns: [true] },
    ],
  },
  {
    title: "Maintenance Management (Optional)",
    features: [
      { name: "Work Order Management", columns: [true] },
      { name: "Preventive Maintenance Scheduling", columns: [true] },
      { name: "Asset Management (Tracking School Equipment)", columns: [true] },
    ],
  },
  {
    title: "Bus Service (Transport or Logistic Service)",
    features: [
      { name: "Bus Route Management", columns: [true] },
      { name: "Bus Tracking (Real-time, if applicable)", columns: [true] },
      { name: "Student Rider Management (including permissions)", columns: [true] },
    ],
  },
  {
    title: "Stella Pay (Payment Gateway) Integration",
    features: [
      { name: "Stella Pay", columns: [true] },
    ],
  },
  {
    title: "Additionals",
    features: [
      { name: "Mobile App", columns: [false] },
      { name: "Email Support", columns: [true ] },
      { name: "Phone & Chat Support (higher tiers)", columns: [false] },
    ],
  },
];


// Accordion Component for Reusability
const Accordion = ({ title, open, onClick, children }) => (
  <div className='p-3 w-full cursor-pointer flex flex-col' onClick={onClick}>
    <div className='flex flex-row justify-between'>
      <div className='font-bold text-[12px] md:text-[15px]'>{title}</div>
      {open ? (
        <AiOutlineMinus className='font-extrabold' size={18} color={'black'} />
      ) : (
        <AiOutlinePlus size={18} className='font-extrabold' color={'black'} />
      )}
    </div>
    {open && <div className='w-full overflow-hidden transition-max-h pt-5'>{children}</div>}
  </div>
);

// Free Component
const FreeComponent = () => {
  const [openSectionIndex, setOpenSectionIndex] = useState(null);
  const [featureToggle, setFeatureToggle] = useState(
    sections.map((section) => section.features.map((feature) => feature.columns[0]))
  );

  const toggleSection = (index) => {
    setOpenSectionIndex(openSectionIndex === index ? null : index);
  };

  const handleFeatureToggle = (e, sectionIndex, featureIndex) => {
    e.stopPropagation(); // Prevent the accordion from closing
    setFeatureToggle((prev) =>
      prev.map((section, sIndex) =>
        section.map((toggle, fIndex) =>
          sectionIndex === sIndex && featureIndex === fIndex ? !toggle : toggle
        )
      )
    );
  };

  return (
    <div className='mt-8 p-3 md:px-6 bg-soft_grey rounded-xl'>
      {/* Plan Header */}
      <div className='flex flex-col md:flex-row md:justify-between md:py-2 md:px-4 md:border-b-2 md:items-center'>
        <div className='flex justify-between space-x-3'>
          <h1 className='font-bold text-[12px] md:text-base'>FREE</h1>
          <span className='flex text-center items-center bg-gray-200 px-2 rounded-2xl space-x-2'>
            <GrStatusGoodSmall style={{ color: 'black' }} className='text-[12px] md:text-base' />
            <span className='text-[12px] md:text-base'>Color</span>
          </span>
        </div>
        <div className='flex justify-between pt-4 md:pt-0'>
          <h1 className='font-bold text-[12px] md:text-base'>Amount</h1>
          <span className='flex text-[12px] md:text-base text-center items-center bg-gray-300 px-2 rounded-2xl space-x-2'>$1000</span>
        </div>
        <div className='flex text-[12px] justify-between pt-4 md:space-x-3 md:items-center md:pt-0 md:text-sm'>
          <div className='p-1 border border-black md:px-4 cursor-pointer md:rounded-xl'>
            <button>Delete</button>
          </div>
          <div className='cursor-pointer'>
            <button>Edit</button>
          </div>
        </div>
      </div>

      {/* Accordion Sections */}
      {sections.map((section, sectionIndex) => (
        <Accordion
          key={sectionIndex}
          title={section.title}
          open={openSectionIndex === sectionIndex}
          onClick={() => toggleSection(sectionIndex)}
        >
          <div className='p-3 flex flex-wrap justify-between'>
            {section.features.map((feature, featureIndex) => (
              <div
                key={featureIndex}
                className='flex items-center cursor-pointer'
                onClick={(e) => handleFeatureToggle(e, sectionIndex, featureIndex)}
              >
                <div className='rounded-full border-2 border-purple-500 w-4 h-4 flex items-center justify-center mr-2'>
                  {/* Conditionally render inner circle */}
                  {featureToggle[sectionIndex][featureIndex] && (
                    <div className='bg-purple-500 w-2 h-2 rounded-full'></div>
                  )}
                </div>
                <span className='text-[12px] md:text-base'>{feature.name}</span>
              </div>
            ))}
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default FreeComponent;
