import * as Collapsible from "@radix-ui/react-collapsible";
import { RowSpacingIcon, Cross2Icon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const DisplayAccount = ({ users, onToggle }) => {
	console.log(users);
	return (
		<div className="flex flex-col gap-2 transition-all ease duration-500">
			{users.map((user) => (
				<Collapsible.Root
					key={user.id}
					className="CollapsibleRoot  border-b border-b-1 border-b-gray-300 mt-1 py-4 "
					open={user.isOpen}
					onOpenChange={() => onToggle(user.id)}
				>
					<Collapsible.Trigger asChild>
						<div className=" px-0 py-3 font-bold flex justify-between users-center  ">
							<span className=" text-white flex items-center gap-1 w-full">
								<span className="bg-primary_color rounded-full p-1  text-white">
									{!user.isOpen ? <FaAngleDown /> : <FaAngleUp />}
								</span>
								<span className="text-black text-sm">Name</span>
							</span>

							<span className="w-full text-sm">{user.name}</span>
						</div>
					</Collapsible.Trigger>
					<Collapsible.Content>
						<ul className=" px-6 flex flex-col gap-4 ">
							<li className="flex justify-between users-center">
								<span className="font-bold w-full">Status</span>
								<span
									className={` w-full ${
										user.status === "Active" ? "text-green" : "text-red-500"
									}`}
								>
									{user.status}
								</span>
							</li>
							<li className="flex justify-between users-center">
								<span className="font-bold w-full">Package</span>
								<span
									className={`w-full ${
										(user.packages === "Free" && "text-green") ||
										(user.packages === "Starter" && "text-blue") ||
										(user.packages === "Standard" && "text-purple-500") ||
										(user.packages === "Pro" && "text-yellow-600")
									}`}
								>
									{user.packages}
								</span>
							</li>
							<li className="flex justify-between users-center">
								<span className="font-bold w-full ">School</span>
								<span className="w-full">{user.school}</span>
							</li>
							<li className="flex justify-between users-center">
								<span className="font-bold w-full">Date Joined</span>
								<span className="w-full  ">{user.joined}</span>
							</li>
							<li className="flex users-center  justify-between">
								<div className="w-full">
									<span className="font-bold w-full">Actions</span>
								</div>
								<div className="flex gap-1 w-full  text-primary_color text-sm  ">
									<button className="focus:scale-105 transition-all ease-in-out duration-100">
										Edit
									</button>
									<button className="focus:scale-105 transition-all ease-in-out duration-100">
										Deactivate
									</button>
									<button className="focus:scale-105 transition-all ease-in-out duration-100">
										Delete
									</button>
								</div>
							</li>
						</ul>
					</Collapsible.Content>
				</Collapsible.Root>
			))}
		</div>
	);
};
export default DisplayAccount;
