import { useEffect, useState } from "react";

import AccountPc from "./accountPc";
import AccountHandheld from "./AccountHandheld";
const Account = () => {
	document.title = "Account Manager | MyStar";
	const [isHandheld, setIsHandheld] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => {
			setIsHandheld(window.innerWidth < 768);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return isHandheld ? <AccountHandheld /> : <AccountPc />;
};

export default Account;
