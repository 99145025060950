import * as Collapsible from "@radix-ui/react-collapsible";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Paginator from "../../../../superAdmin/activity_log/paginator.component";
import Refund from "../refund/Refund";
const Dropdown = () => {
  const token = useSelector(state => state.login.logintoken); // Access token from Redux
  const [data, setData] = useState([]); // State to hold API data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const pageSize = 5; // Set items per page
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false); // State to manage modal visibility
  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.mystarsonline.com/api/Subscription/get-tenant-subscriptions', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        if (response.data.code === 200) {
          setData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]); // Fetch data when the component mounts and when the token changes

  // Pagination logic
  const totalPages = useMemo(() => Math.ceil(data.length / pageSize), [data.length, pageSize]);

  const currentData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return data.slice(startIndex, startIndex + pageSize);
  }, [currentPage, data, pageSize]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (isRefundModalOpen) {
        // Disable scrolling
        document.body.style.overflow = 'hidden';
    } else {
        // Enable scrolling
        document.body.style.overflow = 'auto';
    }

    // Cleanup on component unmount
    return () => {
        document.body.style.overflow = 'auto';
    };
}, [isRefundModalOpen]);

const openRefundModal = () => {
    setIsRefundModalOpen(true);
   
};

const closeRefundModal = () => {
    setIsRefundModalOpen(false);
};

const handleRefundSave = () => {
    // Handle refund save logic
    closeRefundModal();
};
  if (loading) {
    return <div><p className='pt-20'><Skeleton count={8} /> </p></div>; // Display loading state while fetching data
  }

  return (
    <div className="flex flex-col gap-2 transition-all ease duration-500 ">
      {currentData.map((item, index) => (
        <Collapsible.Root
          key={index}
          className="border-b-2  border-grey-200  mb-4"
        >
            <Collapsible.Trigger asChild>
            <div
                className="w-full py-3 text-[16px] border-b flex justify-between items-center cursor-pointer"
            >
                {/* Left Section: Icon and School Name */}
                <div className="flex items-center space-x-2">
                <span className="bg-purple-600 rounded-full p-1 text-white">
                    <FaAngleDown />
                </span>
                <span>School</span>
                </div>

                {/* Tenant Name (centered and can wrap if too long) */}
                <span className="text-center flex-grow ">
                {item.tenantName || 'School'}
                </span>

                {/* Amount (right aligned) */}
                <span className="flex-shrink-0">{`₦${item.amount?.toLocaleString() || 0}`}</span>
            </div>
            </Collapsible.Trigger>




          <Collapsible.Content>
            <div className="px-4 py-2 ">
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between">
                  <span className="font-bold">Name:</span>
                  <span>{item.user || "John Doe"}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-bold">Status:</span>
                  <span className={item.isPaymentSuccessful ? "text-green" : "text-red-600"}>
                    {item.isPaymentSuccessful ? "Successful" : "Failed"}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="font-bold">Plan:</span>
                  <span>{item.planName || "Premium"}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-bold">School:</span>
                  <span>{item.schoolName || "Cova Academy"}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-bold">Date:</span>
                  <span>
                    {new Date(item.createdAt).toLocaleDateString()} ({new Date(item.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
                    </span>

                </div>
                <div className="flex justify-between items-center">
                  <span className="font-bold">Action:</span>
                  <button  onClick={openRefundModal} className="bg-purple-100 text-purple-600 px-2 py-1 rounded-md">
                    Refund
                  </button>
                </div>
              </div>
            </div>
          </Collapsible.Content>
        </Collapsible.Root>
      ))}

      {/* Pagination Controls */}
      <div className="flex mx-auto">
      <Paginator
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={data.length}
        pageSize={pageSize}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />
      </div>
    

       {isRefundModalOpen && (
                <div style={{ position: 'fixed', inset: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 50 }}>
                    <Refund onClose={closeRefundModal} onSave={handleRefundSave} />
                </div>
            )}
    </div>
  );
};

export default Dropdown;
