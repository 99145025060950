import * as Collapsible from "@radix-ui/react-collapsible";
import { RowSpacingIcon, Cross2Icon } from "@radix-ui/react-icons";
import React, { useState, useMemo, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

import DisplayAccount from "../../../components/pages/adminDashboard/account/handHeld/DisplayAccount";

import { useDispatch, useSelector } from "react-redux";

import { fetchTenants } from "../../../redux/slices/tenant_slice";

const AccountHandheld = () => {
	const [isAllSchoolsOpen, setIsAllSchoolsOpen] = useState(false);
	const [isActiveSchoolsOpen, setIsActiveSchoolsOpen] = useState(false);
	const [isInActiveSchoolsOpen, setIsInActiveSchoolsOpen] = useState(false);
	const [isMystarTrackerOpen, setIsMystarTrackerOpen] = useState(false);

	const dispatch = useDispatch();

	const tenants = useSelector((state) => state.tenant.tenants);
	const status = useSelector((state) => state.tenant.status);
	const error = useSelector((state) => state.tenant.error);

	useEffect(() => {
		dispatch(fetchTenants());
	}, [dispatch]);

	// console.log(tenants);
	const [data, setdata] = useState(tenants);
	// console.log(data);
	// console.log(data);
	// const [data, setdata] = useState([
	// 	{
	// 		Name: "john doe",
	// 		Status: "Active",
	// 		Package: "Free",
	// 		School: "Example School",
	// 		Date_Joined: "2023-12-27",
	// 		isOpen: false,
	// 		id: 1,
	// 	},
	// 	{
	// 		Name: "sam doe",
	// 		Status: "Active",
	// 		Package: "Starter",
	// 		School: "Example School",
	// 		Date_Joined: "2023-12-27",
	// 		isOpen: false,
	// 		id: 2,
	// 	},
	// 	{
	// 		Name: "jane doe",
	// 		Status: "Inactive",
	// 		Package: "Free",
	// 		School: "Example School",
	// 		Date_Joined: "2023-12-27",
	// 		isOpen: false,
	// 		id: 3,
	// 	},
	// 	{
	// 		Name: "sarah doe",
	// 		Status: "Inactive",
	// 		Package: "Pro",
	// 		School: "Example School",
	// 		Date_Joined: "2023-12-27",
	// 		isOpen: false,
	// 		id: 4,
	// 	},
	// 	{
	// 		Name: "GeeGee doe",
	// 		Status: "Active",
	// 		Package: "Standard",
	// 		School: "Example School",
	// 		Date_Joined: "2023-12-27",
	// 		isOpen: false,
	// 		id: 5,
	// 	},
	// ]);

	const toggleUserDropdown = (userId) => {
		setdata((prevData) =>
			prevData.map((user) =>
				user.id === userId ? { ...user, isOpen: !user.isOpen } : user
			)
		);
	};

	return (
		<div className=" ">
			<div className="flex flex-col gap-2 transition-all ease duration-500">
				<div className="flex justify-between items-center mt-2">
					<h2 className="font-extrabold  text-2xl">Account Manager</h2>
				</div>

				{/* All Schools  */}
				<Collapsible.Root
					className="CollapsibleRoot  border border-1  px-1 border-grey-200 rounded-xl"
					open={isAllSchoolsOpen}
					onOpenChange={setIsAllSchoolsOpen}
				>
					<Collapsible.Trigger asChild>
						<div
							className={`"  ${
								isAllSchoolsOpen && "border-b-2 border-b-gray-300 "
							} px-2 py-3 font-bold flex justify-between items-center "`}
						>
							<span className="Verified">All Schools</span>
							<span className="bg-primary_color rounded-full p-1 text-white">
								{!isAllSchoolsOpen ? <FaAngleDown /> : <FaAngleUp />}
							</span>
						</div>
					</Collapsible.Trigger>
					<Collapsible.Content>
						<DisplayAccount
							users={tenants}
							onToggle={toggleUserDropdown} // all schools displayed here
						/>
					</Collapsible.Content>
				</Collapsible.Root>

				{/* Active schools */}
				<Collapsible.Root
					className="CollapsibleRoot  border border-1 px-1 border-grey-200 rounded-xl"
					open={isActiveSchoolsOpen}
					onOpenChange={setIsActiveSchoolsOpen}
				>
					<Collapsible.Trigger asChild>
						<div
							className={` ${
								isActiveSchoolsOpen && "border-b-2 border-b-gray-300 "
							} px-2 py-3 font-bold flex justify-between items-center`}
						>
							<span className="Unverified">Active schools</span>
							<span className="bg-primary_color rounded-full p-1 text-white">
								{" "}
								{!isActiveSchoolsOpen ? <FaAngleDown /> : <FaAngleUp />}
							</span>
						</div>
					</Collapsible.Trigger>
					<Collapsible.Content>
						<DisplayAccount
							users={tenants.filter((item) => item.status === "Active")} // filters to display only active schools
							onToggle={toggleUserDropdown}
						/>
					</Collapsible.Content>
				</Collapsible.Root>

				{/* inActive schools */}
				<Collapsible.Root
					className="CollapsibleRoot  border border-1 px-1 border-grey-200 rounded-xl"
					open={isInActiveSchoolsOpen}
					onOpenChange={setIsInActiveSchoolsOpen}
				>
					<Collapsible.Trigger asChild>
						<div
							className={` ${
								isInActiveSchoolsOpen && "border-b-2 border-b-gray-300 "
							} px-2 py-3 font-bold flex justify-between items-center`}
						>
							<span className="Unverified">Inactive schools</span>
							<span className="bg-primary_color rounded-full p-1 text-white">
								{" "}
								{!isInActiveSchoolsOpen ? <FaAngleDown /> : <FaAngleUp />}
							</span>
						</div>
					</Collapsible.Trigger>
					<Collapsible.Content>
						<DisplayAccount
							users={data.filter((item) => item.status === "Inactive")} //filters to display only inactive schools
							onToggle={toggleUserDropdown}
						/>
					</Collapsible.Content>
				</Collapsible.Root>
				{/* Mystar tracker */}
				<Collapsible.Root
					className="CollapsibleRoot  border border-1 px-1 border-grey-200 rounded-xl"
					open={isMystarTrackerOpen}
					onOpenChange={setIsMystarTrackerOpen}
				>
					<Collapsible.Trigger asChild>
						<div
							className={` ${
								isMystarTrackerOpen && "border-b-2 border-b-gray-300 "
							} px-2 py-3 font-bold flex justify-between items-center`}
						>
							<span className="Unverified">Mystar Tracker</span>
							<span className="bg-primary_color rounded-full p-1 text-white">
								{" "}
								{!isMystarTrackerOpen ? <FaAngleDown /> : <FaAngleUp />}
							</span>
						</div>
					</Collapsible.Trigger>
					<Collapsible.Content>
						<DisplayAccount
							users={data.filter((item) => item.status === "Inactive")} //dont know what the my star tracker is
							onToggle={toggleUserDropdown}
						/>
					</Collapsible.Content>
				</Collapsible.Root>
			</div>
		</div>
	);
};

export default AccountHandheld;
