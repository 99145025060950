import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const Refund = ({ onClose, onSave }) => {
  const [refundAmount, setRefundAmount] = useState('');
  const [reason, setReason] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    onSave(); // Call onSave to handle form submission
  };

  return (
    <div className="bg-white rounded-2xl p-6 max-w-sm mx-auto shadow-md h-auto relative">
      <div className="flex justify-end">
        <span className="bg-purple-600 rounded-full p-2 cursor-pointer inline-flex items-center justify-center">
          <AiOutlineClose className="text-white" onClick={onClose} />
        </span>
      </div>

      <div className="mb-6 text-center">
        <p className="text-lg font-bold text-purple-600">REFUND PAYMENT</p>
        <p className="text-sm text-gray-700 pt-2">Refund a payment to a user</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col justify-between items-center space-y-3 ">
          <div className="w-full">
            <label className="block text-xs font-semibold text-gray-700 mb-1">Amount to refund</label>
            <div className="border border-gray-300 rounded-xl flex items-center p-2">
              <input 
                type="text" 
                className="w-full px-2" 
                placeholder="0"
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
              />
              <div className="flex space-x-2 pl-2">
                <button type="button" className="bg-purple-200 text-purple-600 font-semibold px-3 py-1 rounded-lg text-xs">50%</button>
                <button type="button" className="bg-purple-200 text-purple-600 font-semibold px-3 py-1 rounded-lg text-xs">100%</button>
              </div>
            </div>
          </div>

          <div className="w-full">
            <label className="block text-xs font-semibold text-gray-700 mb-1">Amount paid</label>
            <div className="border border-gray-300 rounded-xl p-3 bg-gray-100">
              <p className="text-gray-700 text-base font-bold">50,000</p>
            </div>
          </div>
        </div>

        <div>
          <label className="block text-xs font-semibold text-gray-700 mb-1">Reason</label>
          <textarea 
            className="w-full p-3 border border-gray-300 rounded-xl" 
            rows="3" 
            placeholder="Enter reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <button type="submit" className="bg-primary_color text-white px-6 py-2 rounded-xl">Done</button>
          <div className="border border-primary_color text-primary_color px-6 py-2 rounded-xl">
          <button type="button"  onClick={onClose}>
            Cancel
          </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Refund;
