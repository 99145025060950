import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HeaderTab = () => {
  const [formData, setFormData] = useState({
    title: '',
    subtitle1: '',
    subtitle2: '',
    buttonName: '',
    buttonLink: '',
    headerLogo: null, // Changed to a file object
  });

  const token = useSelector((state) => state.login.logintoken);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      headerLogo: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = new FormData();
      data.append('Title', formData.title);
      data.append('Subtitle1', formData.subtitle1);
      data.append('Subtitle2', formData.subtitle2);
      data.append('Subtitle3', ""); // Sending empty string for subtitle3
      data.append('Subtitle4', ""); // Sending empty string for subtitle4
      data.append('ButtonName', formData.buttonName);
      data.append('ButtonLink', formData.buttonLink);
      if (formData.headerLogo) {
        data.append('HeaderLogo', formData.headerLogo); // Appending the file
      }

      const response = await axios.post(
        'https://api.mystarsonline.com/api/Website/create',
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data
            Authorization: `Bearer ${token}`, // Attach token from Redux store
          },
        }
      );

      if (response.status === 200) {
        toast.success("Data submitted successfully!");
        setFormData({
          title: '',
          subtitle1: '',
          subtitle2: '',
          buttonName: '',
          buttonLink: '',
          headerLogo: null,
        });
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      toast.error('Error submitting data.');
    }
  };

  const handleReset = () => {
    setFormData({
      title: '',
      subtitle1: '',
      subtitle2: '',
      buttonName: '',
      buttonLink: '',
      headerLogo: null,
    });
  };

  return (
    <div className="p-6 ">
      <ToastContainer position="top-center" autoClose={2000} />
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1  gap-4">
          <div>
            <label className="block text-sm font-semibold text-black mb-1">Title</label>
            <div className="border border-gray-300 rounded-lg">
              <textarea
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="w-full p-2"
                placeholder="Lorem Ipsum torem"
                rows="1"
                style={{ overflow: 'hidden' }}
                onInput={(e) => {
                  e.target.style.height = 'auto';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
              />
            </div>
          </div>

          <div className="flex items-center">
            <label className="block text-sm font-semibold text-black mb-1 mr-4">Header Logo</label>
            <div className="border border-gray-300 rounded-lg p-2 flex justify-center items-center cursor-pointer">
              <input
                type="file"
                name="headerLogo"
                onChange={handleFileChange}
                className="w-full p-2"
                accept="image/*"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-semibold text-black mb-1">Subtitle 1</label>
            <div className="border border-gray-300 rounded-lg">
              <textarea
                type="text"
                name="subtitle1"
                value={formData.subtitle1}
                onChange={handleInputChange}
                className="w-full p-2"
                placeholder="Lorem Ipsum torem"
                rows="1"
                style={{ overflow: 'hidden' }}
                onInput={(e) => {
                  e.target.style.height = 'auto';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-semibold text-black mb-1">Subtitle 2</label>
            <div className="border border-gray-300 rounded-lg">
              <textarea
                type="text"
                name="subtitle2"
                value={formData.subtitle2}
                onChange={handleInputChange}
                className="w-full p-2"
                placeholder="Lorem Ipsum torem"
                rows="1"
                style={{ overflow: 'hidden' }}
                onInput={(e) => {
                  e.target.style.height = 'auto';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-semibold text-black mb-1">Button</label>
            <div className="border border-gray-300 rounded-lg">
              <input
                type="text"
                name="buttonName"
                value={formData.buttonName}
                onChange={handleInputChange}
                className="w-full p-2"
                placeholder="Lorem Ipsum torem"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-semibold text-black mb-1">Button Link</label>
            <div className="border border-gray-300 rounded-lg">
              <input
                type="text"
                name="buttonLink"
                value={formData.buttonLink}
                onChange={handleInputChange}
                className="w-full p-2"
                placeholder="Lorem Ipsum torem"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-6 space-x-3">
          <button
            type="button"
            onClick={handleReset}
            className="cursor-pointer bg-primary_color text-white px-4 py-1 rounded-xl"
          >
            Reset
          </button>
          <div className="cursor-pointer border border-primary_color px-4 py-1 rounded-xl inline-block">
            <button type="submit" className="text-primary_color w-full h-full">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HeaderTab;
