
import { useEffect, useState } from "react";
import WebsiteHandheld from "./WebsiteHandheld";
import WebsitePc from "./WebsitePc";

const Website = () => {
	document.title = "Website | MyStar";
	const [isHandheld, setIsHandheld] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => {
			setIsHandheld(window.innerWidth < 768);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return isHandheld ? <WebsiteHandheld /> : <WebsitePc />; // Render component based on isHandheld
};

export default Website;
