
import { useEffect, useState } from "react";
import PackageHandheld from "./PackageHandheld";
import PackagePc from "./PackagePc";

const Package = () => {
	document.title = "Packages | MyStar";
	const [isHandheld, setIsHandheld] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => {
			setIsHandheld(window.innerWidth < 768);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return isHandheld ? <PackageHandheld /> : <PackagePc />; // Render component based on isHandheld
};

export default Package;
