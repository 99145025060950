import * as Tabs from '@radix-ui/react-tabs';
import { useState, useEffect } from 'react';
import Paginator from '../../../components/pages/superAdmin/activity_log/paginator.component';
import CreateDetails from '../../../components/pages/adminDashboard/user/create/create_details';
import HeaderTab from '../../../components/pages/adminDashboard/website/header/header_tab';
import AboutTab from '../../../components/pages/adminDashboard/website/about/about_tab';
import FeaturesTab from '../../../components/pages/adminDashboard/website/features/features_tab';
import TestimonialTab from '../../../components/pages/adminDashboard/website/testimonial/testimonial_tab';
const WebsitePc = () => {
    const [activeTab, setActiveTab] = useState('tab1');
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (isModalOpen) {
            // Disable scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scrolling
            document.body.style.overflow = 'auto';
        }

        // Cleanup on component unmount
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    const activeStyle = {
        color: '#9835D6',
        borderBottom: '2px solid #9835D6'
    };

    const handleAddUserClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="w-full  overflow-x-auto mt-8 bg-white p-4 rounded-xl">
            <Tabs.Root
                className="TabsRoot"
                defaultValue="tab1"
                onValueChange={(value) => setActiveTab(value)}
            >
                <div className='flex justify-between items-center'>
                    <Tabs.List className=" space-x-5 flex flex-row text-sm border-b-2 w-5/6 md:text-lg font-bold" aria-label="Manage">
                        <Tabs.Trigger
                            style={activeTab === 'tab1' ? activeStyle : {}}
                            className="hover:text-primary_color cursor-pointer text-grey"
                            value="tab1"
                        >
                            Headers
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            style={activeTab === 'tab2' ? activeStyle : {}}
                            className="hover:text-primary_color cursor-pointer text-grey"
                            value="tab2"
                        >
                            About Us
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            style={activeTab === 'tab3' ? activeStyle : {}}
                            className="hover:text-primary_color cursor-pointer text-grey"
                            value="tab3"
                        >
                            Features
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            style={activeTab === 'tab4' ? activeStyle : {}}
                            className="hover:text-primary_color cursor-pointer text-grey"
                            value="tab4"
                        >
                            Testimonials
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            style={activeTab === 'tab5' ? activeStyle : {}}
                            className="hover:text-primary_color cursor-pointer text-grey"
                            value="tab5"
                        >
                            My Star Tracker
                        </Tabs.Trigger>
                    </Tabs.List>
                    
                </div>
                
                <Tabs.Content className="" value="tab1">
                    <div>
                      <HeaderTab/>
                    </div>
                </Tabs.Content>
                <Tabs.Content className="" value="tab2">
                    <div>
                       <AboutTab/>
                    </div>
                </Tabs.Content>
                <Tabs.Content className="" value="tab3">
                    <div>
                        <FeaturesTab/>
                    </div>
                </Tabs.Content>
                <Tabs.Content className="" value="tab4">
                    <div>
                        <TestimonialTab/>
                    </div>
                </Tabs.Content>
                <Tabs.Content className="" value="tab5">
                    <div>
                        <h2>My Star Tracker Content</h2>
                        {/* Add your My Star Tracker content here */}
                    </div>
                </Tabs.Content>
             
            </Tabs.Root>

        </div>
    );
};

export default WebsitePc;
