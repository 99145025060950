import * as Collapsible from "@radix-ui/react-collapsible";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import React, { useState } from "react";
import Web from "../../../components/pages/adminDashboard/package/handHeld/web/Web";
import Mobile from "../../../components/pages/adminDashboard/package/handHeld/mobile/Mobile";

const PackageHandheld = () => {
	const [isMyStarOpen, setIsMyStarOpen] = useState(false);
	const [isMyStarTrackerOpen, setIsMyStarTrackerOpen] = useState(false);

	return (
		<div className="flex flex-col gap-2 transition-all ease duration-500 mx-4">
			<h2 className="font-extrabold text-2xl">Packages</h2>

			{/* My Star Dropdown */}
			<Collapsible.Root
				className="CollapsibleRoot border border-1 px-1 border-grey-200 rounded-xl"
				open={isMyStarOpen}
				onOpenChange={setIsMyStarOpen}
			>
				<Collapsible.Trigger asChild>
					<div
						className={`${
							isMyStarOpen && "border-b-2 border-b-gray-300"
						} px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
					>
						<span className="PackageName">My Star</span>
						<span className="bg-primary_color rounded-full p-1 text-white">
							{!isMyStarOpen ? <FaAngleDown /> : <FaAngleUp />}
						</span>
					</div>
				</Collapsible.Trigger>
				<Collapsible.Content className="">
					{/* Add any additional content inside the dropdown here if needed */}
                    <Web/>
				</Collapsible.Content>
			</Collapsible.Root>

			{/* My Star Tracker Dropdown */}
			<Collapsible.Root
				className="CollapsibleRoot border border-1 px-1 border-grey-200 rounded-xl"
				open={isMyStarTrackerOpen}
				onOpenChange={setIsMyStarTrackerOpen}
			>
				<Collapsible.Trigger asChild>
					<div
						className={`${
							isMyStarTrackerOpen && "border-b-2 border-b-gray-300"
						} px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
					>
						<span className="PackageName">My Star Tracker</span>
						<span className="bg-primary_color rounded-full p-1 text-white">
							{!isMyStarTrackerOpen ? <FaAngleDown /> : <FaAngleUp />}
						</span>
					</div>
				</Collapsible.Trigger>
				<Collapsible.Content>
					{/* Add any additional content inside the dropdown here if needed */}
                    <Mobile/>
				</Collapsible.Content>
			</Collapsible.Root>
		</div>
	);
};

export default PackageHandheld;
