import * as Collapsible from "@radix-ui/react-collapsible";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import React, { useState } from "react";

const PaymentAccount = () => {
  const [isFlutterwaveOpen, setIsFlutterwaveOpen] = useState(false);
  const [isPaystackOpen, setIsPaystackOpen] = useState(false);
  const [isFlutterwaveEnabled, setIsFlutterwaveEnabled] = useState(true);
  const [isPaystackEnabled, setIsPaystackEnabled] = useState(true);

  const handleToggle = (gateway) => {
    if (gateway === 'flutterwave') {
      setIsFlutterwaveEnabled(!isFlutterwaveEnabled);
    } else if (gateway === 'paystack') {
      setIsPaystackEnabled(!isPaystackEnabled);
    }
  };

  return (
    <div className=" space-y-8">
      {/* Flutterwave Dropdown */}
      <Collapsible.Root
        className="border border-1 px-1 border-grey-200 rounded-xl"
        open={isFlutterwaveOpen}
        onOpenChange={setIsFlutterwaveOpen}
      >
        <Collapsible.Trigger asChild>
          <div
            className={`${
              isFlutterwaveOpen && "border-b-2 border-b-gray-300"
            } px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
          >
            <span>Flutterwave</span>
            <div className="flex items-center gap-2">
              <input
                className="me-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-black/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-gray-200 after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary_color checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-white/25 dark:after:bg-surface-dark dark:checked:bg-primary dark:checked:after:bg-primary"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckFlutterwave"
                checked={isFlutterwaveEnabled}
                onChange={() => handleToggle('flutterwave')}
              />
              <span className="bg-primary_color rounded-full p-1 text-white">
                {!isFlutterwaveOpen ? <FaAngleDown /> : <FaAngleUp />}
              </span>
            </div>
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <div className="px-4 py-2 space-y-3">
            {/* Flutterwave Form */}
            <select className="w-full border border-gray-300 rounded-lg p-2">
              <option>LIVE</option>
              <option>TEST</option>
            </select>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Secret Key
              </label>
              <div className="w-full border border-gray-300 rounded-lg p-2">
                <input type="text" className="" placeholder="Lorem Ipsum torem" />
              </div>
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Public Key
              </label>
              <div className="w-full border border-gray-300 rounded-lg p-2">
                <input type="text" className="" placeholder="Lorem Ipsum torem" />
              </div>
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">Hash</label>
              <div className="w-full border border-gray-300 rounded-lg p-2">
                <input type="text" className="" placeholder="Lorem Ipsum torem" />
              </div>
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Payment Gateway Title
              </label>
              <div className="w-full border border-gray-300 rounded-lg p-2">
                <input type="text" className="" placeholder="Lorem Ipsum torem" />
              </div>
            </div>

            <div className="border border-gray-300 rounded-lg p-2 flex items-center justify-between">
              <button className="bg-gray-200 text-black p-1 rounded-lg">Choose file</button>
              <span className="ml-2">No file chosen</span>
            </div>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>

      {/* Paystack Dropdown */}
      <Collapsible.Root
        className="border border-1 px-1 border-grey-200 rounded-xl"
        open={isPaystackOpen}
        onOpenChange={setIsPaystackOpen}
      >
        <Collapsible.Trigger asChild>
          <div
            className={`${
              isPaystackOpen && "border-b-2 border-b-gray-300"
            } px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
          >
            <span>Paystack</span>
            <div className="flex items-center gap-2">
              <input
                className="me-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-black/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-gray-200 after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary_color checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-white/25 dark:after:bg-surface-dark dark:checked:bg-primary dark:checked:after:bg-primary"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckPaystack"
                checked={isPaystackEnabled}
                onChange={() => handleToggle('paystack')}
              />
              <span className="bg-primary_color rounded-full p-1 text-white">
                {!isPaystackOpen ? <FaAngleDown /> : <FaAngleUp />}
              </span>
            </div>
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <div className="px-4 py-2 space-y-3">
            {/* Paystack Form */}
            <select className="w-full border border-gray-300 rounded-lg p-2">
              <option>LIVE</option>
              <option>TEST</option>
            </select>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Secret Key
              </label>
              <div className="w-full border border-gray-300 rounded-lg p-2">
                <input type="text" className="" placeholder="Lorem Ipsum torem" />
              </div>
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Public Key
              </label>
              <div className="w-full border border-gray-300 rounded-lg p-2">
                <input type="text" className="" placeholder="Lorem Ipsum torem" />
              </div>
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">Hash</label>
              <div className="w-full border border-gray-300 rounded-lg p-2">
                <input type="text" className="" placeholder="Enter Paystack Hash" />
              </div>
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Payment Gateway Title
              </label>
              <div className="w-full border border-gray-300 rounded-lg p-2">
                <input type="text" className="" placeholder="Lorem Ipsum torem" />
              </div>
            </div>

            <div className="border border-gray-300 rounded-lg p-2 flex items-center justify-between">
              <button className="bg-gray-200 text-black p-1 rounded-lg">Choose file</button>
              <span className="ml-2">No file chosen</span>
            </div>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  );
};

export default PaymentAccount;
