import * as Collapsible from "@radix-ui/react-collapsible";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import React, { useState } from "react";
import Dropdown from "../../../components/pages/adminDashboard/payment/handHeld/history/dropdown";
import PaymentAccount from "../../../components/pages/adminDashboard/payment/handHeld/account/payment_account";

const PaymentHandheld = () => {
  const [isPaymentsAccountsOpen, setIsPaymentsAccountsOpen] = useState(false);
  const [isPaymentHistoryOpen, setIsPaymentHistoryOpen] = useState(false);

  return (
    <div className="flex flex-col gap-2 transition-all ease duration-500 mx-4 ">
      <h2 className="font-extrabold text-2xl">Payments</h2>

      {/* Payments Accounts Dropdown */}
      <Collapsible.Root
        className="border border-1 px-1 border-grey-200 rounded-xl"
        open={isPaymentsAccountsOpen}
        onOpenChange={setIsPaymentsAccountsOpen}
      >
        <Collapsible.Trigger asChild>
          <div
            className={`${
              isPaymentsAccountsOpen && "border-b-2 border-b-gray-300"
            } px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
          >
            <span>Payments Accounts</span>
            <span className="bg-primary_color rounded-full p-1 text-white">
              {!isPaymentsAccountsOpen ? <FaAngleDown /> : <FaAngleUp />}
            </span>
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content>
          {/* Additional content inside dropdown */}
          <div className="px-4 py-2">
            <PaymentAccount/>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>

      {/* Payment History Dropdown */}
      <Collapsible.Root
        className="border border-1 px-1 border-grey-200 rounded-xl"
        open={isPaymentHistoryOpen}
        onOpenChange={setIsPaymentHistoryOpen}
      >
        <Collapsible.Trigger asChild>
          <div
            className={`${
              isPaymentHistoryOpen && ""
            } px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
          >
            <span>Payment History</span>
            <span className="bg-primary_color rounded-full p-1 text-white">
              {!isPaymentHistoryOpen ? <FaAngleDown /> : <FaAngleUp />}
            </span>
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content>
          {/* Additional content inside dropdown */}
          <div className="px-4 py-2">
              <Dropdown/>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  );
};

export default PaymentHandheld;
