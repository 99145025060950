import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedPlan } from "../../../redux/slices/savedplan_slice"; // Import the action from savedplan

const PlanTabs = () => {
	const [activeTab, setActiveTab] = useState("monthly");
	const [monthlyPlans, setMonthlyPlans] = useState([]);
	const [annualPlans, setAnnualPlans] = useState([]);
	const navigate = useNavigate();
	const dispatch = useDispatch(); // Initialize dispatch

	// Get the token from Redux store

	const registerToken = useSelector((state) => state.register.token); // Register token
	const resubscribeToken = useSelector(
		(state) => state.resubscribe.resubscribetoken
	); // Resubscribe token

	useEffect(() => {
		const fetchPlans = async () => {
			try {
				const response = await axios.get(
					"https://api.mystarsonline.com/api/Subscription/get-WebPlans"
				);
				if (response.data.code === 200) {
					const allPlans = response.data.data;
					const monthly = allPlans
						.map((plan) => plan.monthlyPlan)
						.filter(Boolean)
						.slice(-4);
					const annually = allPlans
						.map((plan) => plan.annualPlan)
						.filter(Boolean)
						.slice(-4);

					setMonthlyPlans(monthly);
					setAnnualPlans(annually);
				}
			} catch (error) {
				console.error("Error fetching plans:", error);
			}
		};
		fetchPlans();
	}, []);

	const formatPrice = (price) => (
		<>
			{/* <sup className="text-lg b  flex   ">₦</sup> */}
			{(price / 100).toLocaleString()}
		</>
	);

	const handleButtonClick = (plan) => {
		// Check if the user has neither register token nor resubscribe token
		if (!registerToken && !resubscribeToken) {
			const isTrial = true;

			// Save the selected plan to Redux store
			dispatch(setSelectedPlan({ plan, isTrial }));

			// Navigate to the signup page
			navigate("/signup");
		} else if (resubscribeToken && !registerToken) {
			// If the user has the resubscribe token but no register token, navigate to the subscribe page
			navigate("/subscribe", { state: { plan, isTrial: true } });
		} else {
			// If the register token is present, navigate to the subscribe page
			navigate("/subscribe", { state: { plan, isTrial: true } });
		}
	};

	const plansToDisplay = activeTab === "monthly" ? monthlyPlans : annualPlans;

	return (
		<div>
			<div className="flex justify-center items-center p-4 ">
				<div className="border-4 rounded-xl border-light_grey overflow-hidden flex">
					<button
						className={`font-bold cursor-pointer text-sm md:text-lg 2xl:text-2xl px-3 py-2 ${
							activeTab === "monthly"
								? "bg-purple-600 text-white"
								: "bg-faint_grey text-black"
						}`}
						onClick={() => setActiveTab("monthly")}
					>
						Monthly Plans
					</button>
					<button
						className={`font-bold cursor-pointer text-sm md:text-lg 2xl:text-2xl px-3 py-2 ${
							activeTab === "annually"
								? "bg-purple-600 text-white"
								: "bg-faint_grey text-black"
						}`}
						onClick={() => setActiveTab("annually")}
					>
						Yearly Plans
					</button>
				</div>
			</div>

			<div className="p-4 pt-10 2xl:pt-14">
				<div className="flex flex-col sm:grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-2">
					{/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 2xl:gap-10"> */}
					{plansToDisplay.map((plan) => (
						<div
							key={plan.id}
							className="bg- p-6  rounded-xl border-2 border-[#DCDCDC]  h-full col-span-1 flex flex-col items-center"
							// className="bg-white p-6 shadow-lg rounded-xl border-2 border-gray-200 flex flex-col items-center justify-between min-h-[450px] h-full"
						>
							<h3
								className="font-bold text-lg 2xl:text-4xl text-center"
								style={{ color: plan.colorCode }}
							>
								{plan.name}
							</h3>
							<p className="text-sm sm:text-base font-bold text-center text-gray-600 mt-1 ">
								{/* <p className="text-sm text-center text-gray-600 mt-1 2xl:mt-6 2xl:text-3xl"> */}
								{plan.maxStudentCapacity > 10000
									? "(Unlimited students)"
									: `(1-${plan.maxStudentCapacity} students)`}
							</p>
							<hr className="m-auto w-full bg-background my-2" />
							<p className="text-4xl  font-bold text-center mt-4 relative before:text-[20px]  before:content-['&#8358;'] before:top-[-15px] before:absolute before:left-[-15px]  ">
								{/* <p className="text-xl font-bold text-center mt-4 2xl:mt-8 2xl:text-4xl"> */}
								{formatPrice(plan.amount)}
							</p>
							<p className="text-base font-normal text-center text-gray-500 2xl:text-xl">
								/School
							</p>

							<div className="mt-2 w-full  flex flex-col items-stretch ">
								{/* <div className="my-2 w-full flex-1 flex flex-col "> */}
								<h4 className="bg-gray-500 hover:bg-gray-700 text-white font-bold  sm:text-xl  py-2 text-center rounded   ">
									{/* <h4 className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 text-center rounded 2xl:text-3xl"> */}
									Description
								</h4>
								<p className="mt-4 text-gray-700   text-center lg:min-h-[5rem] ">
									{/* <p className="mt-4 text-gray-700 text-pretty flex-1 text-center 2xl:text-xl"> */}
									{plan.description}
								</p>
							</div>
							<hr className="m-auto w-3/5 bg-background my-2 " />
							<div className="mt-2 w-full ">
								<h4 className="mt-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 text-center rounded  sm:text-xl ">
									Key Features
								</h4>
								<p className=" text-gray-700 text-center  sm:min-h-[8rem]  lg:min-h-[10rem] mt-4">
									{plan.keyFeatures}
								</p>
							</div>
							<hr className="m-auto w-3/5 bg-background my-2" />
							<div className="my-2 w-full ">
								<h4 className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 text-center rounded mb-2  ">
									Ideal For
								</h4>

								<p className="text-center  text-gray-700    sm:min-h-[3rem]  lg:min-h-[6rem] mt-4 break-words">
									{/* <p className="text-center text-gray-700  lg:text-xl  sm:min-h-[3rem]  lg:min-h-[6rem] mt-4"> */}
									{plan.idealFor}
								</p>
							</div>

							<div className="flex flex-row pt-3 text-sm ">
								<button
									className="cursor-pointer bg-purple-500 text-white font-bold py-2 px-4 text-center rounded-[10px] 2xl:text-xl"
									onClick={() => handleButtonClick(plan)}
								>
									Start Free Trial
								</button>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PlanTabs;
