import React, { useState } from "react";
import Sidebar from "../components/pages/adminDashboard/side-bar";
import TopBar from "../components/pages/adminDashboard/top-bar";

function AdminLayout({ children }) {
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	return (
		<section className="w-full h-auto overflow-hidden ">
			<TopBar toggleSidebar={toggleSidebar} />
			<div className="w-full md:flex px-2 sm:px-5 lg:px-10 items-start pt-20 lg:pt-32  ">
				{isSidebarOpen && (
					<div className="hidden lg:block  ">
						{/* <div className="pt-14 hidden md:block  "> */}

						<Sidebar />
					</div>
				)}
				<div
					className={` transition-all duration-300 w-full  ${
						isSidebarOpen ? "lg:ml-4" : "lg:ml-0"
					} `}
				>
					{children}
				</div>
			</div>
		</section>
	);
}

export default AdminLayout;
