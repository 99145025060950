import * as Tabs from "@radix-ui/react-tabs";
import { useState } from "react";
import Paginator from "../../../components/pages/superAdmin/activity_log/paginator.component";
import AllSchool from "../../../components/pages/adminDashboard/account/all_school/all_school";
import Active from "../../../components/pages/adminDashboard/account/active/active";
import InActive from "../../../components/pages/adminDashboard/account/inactive/inactive";
const AccountPc = () => {
	const [activeTab, setActiveTab] = useState("tab1");

	const activeStyle = {
		color: "#9835D6",
		borderBottom: "2px solid #9835D6",
	};

	return (
		<div className="w-full mt-8 bg-white p-4 rounded-xl">
			<Tabs.Root
				className="TabsRoot"
				defaultValue="tab1"
				onValueChange={(value) => setActiveTab(value)}
			>
				<div className="flex justify-between items-center">
					<Tabs.List
						className="space-x-5 flex flex-row text-sm border-b-2 w-5/6 md:text-lg 2xl:text-2xl font-bold"
						aria-label="Manage"
					>
						<Tabs.Trigger
							style={activeTab === "tab1" ? activeStyle : {}}
							className="hover:text-primary_color cursor-pointer text-grey"
							value="tab1"
						>
							All Schools
						</Tabs.Trigger>
						<Tabs.Trigger
							style={activeTab === "tab2" ? activeStyle : {}}
							className="hover:text-primary_color cursor-pointer text-grey"
							value="tab2"
						>
							Active
						</Tabs.Trigger>
						<Tabs.Trigger
							style={activeTab === "tab3" ? activeStyle : {}}
							className="hover:text-primary_color cursor-pointer text-grey"
							value="tab3"
						>
							InActive
						</Tabs.Trigger>
						<Tabs.Trigger
							style={activeTab === "tab4" ? activeStyle : {}}
							className="hover:text-primary_color cursor-pointer text-grey"
							value="tab4"
						>
							My Star Tracker
						</Tabs.Trigger>
					</Tabs.List>
				</div>

				<Tabs.Content value="tab1">
					<AllSchool />
				</Tabs.Content>
				<Tabs.Content value="tab2">
					<Active />
				</Tabs.Content>
				<Tabs.Content value="tab3">
					<InActive />
				</Tabs.Content>
				<Tabs.Content value="tab4">
					{/* Additional content for tab4 */}
				</Tabs.Content>
			</Tabs.Root>
		</div>
	);
};

export default AccountPc;
