import React, { useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';

import Verified from '../../../components/pages/adminDashboard/payment/history/verified_tab';
import PaymentAccount from '../../../components/pages/adminDashboard/payment/account/payment_account';

const PaymentPc = () => {
  const [activeTab, setActiveTab] = useState('paymentAccount'); // Updated initial state

  const activeStyle = {
    color: '#9835D6',
    borderBottom: '2px solid #9835D6'
  };

  return (
    <div className='w-full mt-8 bg-white p-4 rounded-xl'>
      <Tabs.Root 
         className="TabsRoot"
         value={activeTab}
         onValueChange={setActiveTab}
      >
        <Tabs.List className="space-x-2 flex flex-row text-sm border-b-2 w-full md:text-lg font-bold">
          <Tabs.Trigger 
              style={activeTab === 'paymentAccount' ? activeStyle : {}}
              className="hover:text-primary_color cursor-pointer"
              value="paymentAccount"
          >
            Payment Account
          </Tabs.Trigger>
          <Tabs.Trigger 
               style={activeTab === 'paymentHistory' ? activeStyle : {}}
               className="hover:text-primary_color cursor-pointer"
               value="paymentHistory"
          >
            Payment History
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="paymentAccount">
            <PaymentAccount/>
        </Tabs.Content>
        <Tabs.Content value="paymentHistory">
        <Verified />
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
};

export default PaymentPc;
