import * as Collapsible from "@radix-ui/react-collapsible";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import React, { useState } from "react";
import HeaderTab from "../../../components/pages/adminDashboard/website/handHeld/header/header_tab";
import AboutTab from "../../../components/pages/adminDashboard/website/handHeld/about/about_tab";
import FeaturesTab from "../../../components/pages/adminDashboard/website/handHeld/features/features_tab";
import TestimonialTab from "../../../components/pages/adminDashboard/website/handHeld/testimonial/testimonial_tab";

const WebsiteHandheld = () => {
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const [isDisplayOpen, setIsDisplayOpen] = useState(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(false);
  const [isTestimonialsOpen, setIsTestimonialsOpen] = useState(false);
  const [isMyStarTrackerOpen, setIsMyStarTrackerOpen] = useState(false);

  return (
    <div className="flex flex-col gap-2 transition-all ease duration-500 mx-4">
      <h2 className="font-extrabold text-2xl">Website</h2>

      {/* Header Dropdown */}
      <Collapsible.Root
        className="border border-1 px-1 border-grey-200 rounded-xl"
        open={isHeaderOpen}
        onOpenChange={setIsHeaderOpen}
      >
        <Collapsible.Trigger asChild>
          <div
            className={`${
              isHeaderOpen && "border-b-2 border-b-gray-300"
            } px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
          >
            <span>Header</span>
            <span className="bg-purple-600 rounded-full p-1 text-white">
              {!isHeaderOpen ? <FaAngleDown /> : <FaAngleUp />}
            </span>
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <div className="px-4 py-2">
            <HeaderTab/>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>

      {/* Display Dropdown */}
      <Collapsible.Root
        className="border border-1 px-1 border-grey-200 rounded-xl"
        open={isDisplayOpen}
        onOpenChange={setIsDisplayOpen}
      >
        <Collapsible.Trigger asChild>
          <div
            className={`${
              isDisplayOpen && "border-b-2 border-b-gray-300"
            } px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
          >
            <span>Display</span>
            <span className="bg-purple-600 rounded-full p-1 text-white">
              {!isDisplayOpen ? <FaAngleDown /> : <FaAngleUp />}
            </span>
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <div className="px-4 py-2">
            <p>Details about Display...</p>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>

      {/* About Us Dropdown */}
      <Collapsible.Root
        className="border border-1 px-1 border-grey-200 rounded-xl"
        open={isAboutUsOpen}
        onOpenChange={setIsAboutUsOpen}
      >
        <Collapsible.Trigger asChild>
          <div
            className={`${
              isAboutUsOpen && "border-b-2 border-b-gray-300"
            } px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
          >
            <span>About Us</span>
            <span className="bg-purple-600 rounded-full p-1 text-white">
              {!isAboutUsOpen ? <FaAngleDown /> : <FaAngleUp />}
            </span>
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <div className="px-4 py-2">
               <AboutTab/>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>

      {/* Features Dropdown */}
      <Collapsible.Root
        className="border border-1 px-1 border-grey-200 rounded-xl"
        open={isFeaturesOpen}
        onOpenChange={setIsFeaturesOpen}
      >
        <Collapsible.Trigger asChild>
          <div
            className={`${
              isFeaturesOpen && "border-b-2 border-b-gray-300"
            } px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
          >
            <span>Features</span>
            <span className="bg-purple-600 rounded-full p-1 text-white">
              {!isFeaturesOpen ? <FaAngleDown /> : <FaAngleUp />}
            </span>
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <div className="px-4 py-2">
            <FeaturesTab/>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>

      {/* Testimonials Dropdown */}
      <Collapsible.Root
        className="border border-1 px-1 border-grey-200 rounded-xl"
        open={isTestimonialsOpen}
        onOpenChange={setIsTestimonialsOpen}
      >
        <Collapsible.Trigger asChild>
          <div
            className={`${
              isTestimonialsOpen && "border-b-2 border-b-gray-300"
            } px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
          >
            <span>Testimonials</span>
            <span className="bg-purple-600 rounded-full p-1 text-white">
              {!isTestimonialsOpen ? <FaAngleDown /> : <FaAngleUp />}
            </span>
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <div className="px-4 py-2">
                <TestimonialTab/>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>

      {/* My Star Tracker Dropdown */}
      <Collapsible.Root
        className="border border-1 px-1 border-grey-200 rounded-xl"
        open={isMyStarTrackerOpen}
        onOpenChange={setIsMyStarTrackerOpen}
      >
        <Collapsible.Trigger asChild>
          <div
            className={`${
              isMyStarTrackerOpen && "border-b-2 border-b-gray-300"
            } px-2 py-3 font-bold flex justify-between items-center cursor-pointer`}
          >
            <span>My Star Tracker</span>
            <span className="bg-purple-600 rounded-full p-1 text-white">
              {!isMyStarTrackerOpen ? <FaAngleDown /> : <FaAngleUp />}
            </span>
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <div className="px-4 py-2">
            <p>Details about My Star Tracker...</p>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  );
};

export default WebsiteHandheld;
