import React, { useState } from 'react';

const PaymentAccount = () => {
  const [isFlutterwaveEnabled, setFlutterwaveEnabled] = useState(true);
  const [isPaystackEnabled, setPaystackEnabled] = useState(true);

  const handleToggle = (gateway) => {
    if (gateway === 'flutterwave') {
      setFlutterwaveEnabled(!isFlutterwaveEnabled);
    } else if (gateway === 'paystack') {
      setPaystackEnabled(!isPaystackEnabled);
    }
  };

  return (
    <div className="p-6 space-y-8">
      <section className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Flutterwave Section */}
        <div>
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-bold text-black">Flutterwave</h3>
            <div className="inline-flex items-center">
              <input
                className="me-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-black/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-gray-200 after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary_color checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-white/25 dark:after:bg-surface-dark dark:checked:bg-primary dark:checked:after:bg-primary"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckFlutterwave"
                checked={isFlutterwaveEnabled}
                onChange={() => handleToggle('flutterwave')}
              />
            </div>
          </div>

          <div className=" rounded-xl p-4 space-y-4">
              <div className="flex justify-center text-center mb-4">
                    <div className="border border-gray-300 rounded-xl h-40 w-60 flex justify-center items-center">
                        <div className="text-gray-400 flex flex-col justify-center items-center">
                        <svg
                            className="w-16 h-16"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                            <circle cx="8.5" cy="8.5" r="1.5"></circle>
                            <path d="M21 15l-5-5L5 21"></path>
                        </svg>
                        <p className="text-sm">Upload Image</p>
                        </div>
                    </div>
                </div>


            <select className="w-full border border-gray-300 rounded-lg p-2">
              <option>LIVE</option>
              <option>TEST</option>
            </select>
            <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-1">Secret Key</label>
                    <div className='w-full border border-gray-300 rounded-lg p-2'>
                    <input
                    type="text"
                    className=""
                    placeholder="Lorem Ipsum torem"
                    />
                    </div>
            </div>
           <div>
           <label className="block text-sm font-semibold text-gray-700 mb-1">Public Key</label>
             <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
           </div>
           
           <div>
           <label className="block text-sm font-semibold text-gray-700 mb-1">Hash</label>
                 <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
           </div>
            
            <div>
                <label className="block text-sm font-semibold text-gray-700 mb-1">Payment gateway title</label>
                <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
            </div>
          

            <div className="border border-gray-300 rounded-lg p-2 flex items-center justify-between">
              <button className="bg-gray-200 text-black p-1 rounded-lg">Choose file</button>
              <span className="ml-2">Lorem Ipsum torem</span>
            </div>
          </div>
        </div>
        

        {/* Paystack Section */}
        <div>
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-bold text-black">Paystack</h3>
            <div className="inline-flex items-center">
              <input
                className="me-2 mt-[0.3rem] h-3.5 w-9 appearance-none rounded-[0.4375rem] bg-black/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-gray-200 after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary_color checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-white/25 dark:after:bg-surface-dark dark:checked:bg-primary dark:checked:after:bg-primary"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckPaystack"
                checked={isPaystackEnabled}
                onChange={() => handleToggle('paystack')}
              />
            </div>
          </div>

          <div className=" rounded-xl p-4 space-y-4">
                <div className="flex justify-center text-center mb-4">
                            <div className="border border-gray-300 rounded-xl h-40 w-60 flex justify-center items-center">
                                <div className="text-gray-400 flex flex-col justify-center items-center">
                                <svg
                                    className="w-16 h-16"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                    <path d="M21 15l-5-5L5 21"></path>
                                </svg>
                                <p className="text-sm">Upload Image</p>
                                </div>
                            </div>
                        </div>

            <select className="w-full border border-gray-300 rounded-lg p-2">
              <option>LIVE</option>
              <option>TEST</option>
            </select>
            <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-1">Secret Key</label>
                    <div className='w-full border border-gray-300 rounded-lg p-2'>
                    <input
                    type="text"
                    className=""
                    placeholder="Lorem Ipsum torem"
                    />
                    </div>
            </div>
           <div>
           <label className="block text-sm font-semibold text-gray-700 mb-1">Public Key</label>
             <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
           </div>
           
           <div>
           <label className="block text-sm font-semibold text-gray-700 mb-1">Hash</label>
                 <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
           </div>
            
            <div>
                <label className="block text-sm font-semibold text-gray-700 mb-1">Payment gateway title</label>
                <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
            </div>
            <div className="border border-gray-300 rounded-lg p-2 flex items-center justify-between">
              <button className="bg-gray-200 text-black p-1 rounded-lg">Choose file</button>
              <span className="ml-2">Lorem Ipsum torem</span>
            </div>
          </div>
        </div>


        {/* Flutterwave Section */}
       <div>
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-bold text-black">Flutterwave</h3>
            <div className="inline-flex items-center">
              <input
                className="me-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-black/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-gray-200 after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary_color checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-white/25 dark:after:bg-surface-dark dark:checked:bg-primary dark:checked:after:bg-primary"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckFlutterwave"
                checked={isFlutterwaveEnabled}
                onChange={() => handleToggle('flutterwave')}
              />
            </div>
          </div>

          <div className=" rounded-xl p-4 space-y-4">
              <div className="flex justify-center text-center mb-4">
                    <div className="border border-gray-300 rounded-xl h-40 w-60 flex justify-center items-center">
                        <div className="text-gray-400 flex flex-col justify-center items-center">
                        <svg
                            className="w-16 h-16"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                            <circle cx="8.5" cy="8.5" r="1.5"></circle>
                            <path d="M21 15l-5-5L5 21"></path>
                        </svg>
                        <p className="text-sm">Upload Image</p>
                        </div>
                    </div>
                </div>


            <select className="w-full border border-gray-300 rounded-lg p-2">
              <option>LIVE</option>
              <option>TEST</option>
            </select>
            <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-1">Secret Key</label>
                    <div className='w-full border border-gray-300 rounded-lg p-2'>
                    <input
                    type="text"
                    className=""
                    placeholder="Lorem Ipsum torem"
                    />
                    </div>
            </div>
           <div>
           <label className="block text-sm font-semibold text-gray-700 mb-1">Public Key</label>
             <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
           </div>
           
           <div>
           <label className="block text-sm font-semibold text-gray-700 mb-1">Hash</label>
                 <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
           </div>
            
            <div>
                <label className="block text-sm font-semibold text-gray-700 mb-1">Payment gateway title</label>
                <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
            </div>
          

            <div className="border border-gray-300 rounded-lg p-2 flex items-center justify-between">
              <button className="bg-gray-200 text-black p-1 rounded-lg">Choose file</button>
              <span className="ml-2">Lorem Ipsum torem</span>
            </div>
          </div>
          
        </div>

        {/* Paystack Section */}
        <div>
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-bold text-black">Paystack</h3>
            <div className="inline-flex items-center">
              <input
                className="me-2 mt-[0.3rem] h-3.5 w-9 appearance-none rounded-[0.4375rem] bg-black/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-gray-200 after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary_color checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-white/25 dark:after:bg-surface-dark dark:checked:bg-primary dark:checked:after:bg-primary"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckPaystack"
                checked={isPaystackEnabled}
                onChange={() => handleToggle('paystack')}
              />
            </div>
          </div>

          <div className=" rounded-xl p-4 space-y-4">
                <div className="flex justify-center text-center mb-4">
                            <div className="border border-gray-300 rounded-xl h-40 w-60 flex justify-center items-center">
                                <div className="text-gray-400 flex flex-col justify-center items-center">
                                <svg
                                    className="w-16 h-16"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                    <path d="M21 15l-5-5L5 21"></path>
                                </svg>
                                <p className="text-sm">Upload Image</p>
                                </div>
                            </div>
                        </div>

            <select className="w-full border border-gray-300 rounded-lg p-2">
              <option>LIVE</option>
              <option>TEST</option>
            </select>
            <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-1">Secret Key</label>
                    <div className='w-full border border-gray-300 rounded-lg p-2'>
                    <input
                    type="text"
                    className=""
                    placeholder="Lorem Ipsum torem"
                    />
                    </div>
            </div>
           <div>
           <label className="block text-sm font-semibold text-gray-700 mb-1">Public Key</label>
             <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
           </div>
           
           <div>
           <label className="block text-sm font-semibold text-gray-700 mb-1">Hash</label>
                 <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
           </div>
            
            <div>
                <label className="block text-sm font-semibold text-gray-700 mb-1">Payment gateway title</label>
                <div className='w-full border border-gray-300 rounded-lg p-2'>
                <input
                type="text"
                className=""
                placeholder="Lorem Ipsum torem"
                />
                </div>
            </div>
            <div className="border border-gray-300 rounded-lg p-2 flex items-center justify-between">
              <button className="bg-gray-200 text-black p-1 rounded-lg">Choose file</button>
              <span className="ml-2">Lorem Ipsum torem</span>
            </div>
          </div>
        </div>
      </section>

       
            
      
    </div>
  );
};

export default PaymentAccount;
