import { useEffect, useState } from "react";
import PaymentHandheld from "./PaymentHandheld";
import PaymentPc from "./PaymentPc";
const Payment = () => {
	document.title = "Payments| MyStar";
	const [isHandheld, setIsHandheld] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => {
			setIsHandheld(window.innerWidth < 768);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return isHandheld ? <PaymentHandheld /> : <PaymentPc />; // Render component based on isHandheld
};

export default Payment;
